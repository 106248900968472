<template>
  <div class="container">
    <div class="grid justify-content-center">
      <div class="col-12 md:col-8 comp-grid">
        <div class="card">
          <Button icon="pi pi-arrow-left" class="p-button-sm p-button-rounded px-4" :label="$t('back_to_home')"
            @click="() => { $router.push('/'); }" />
          <Divider></Divider>
          <div v-if="payment_status != 'Paid'" class="grid">
            <div class="col-12 md:col-12 comp-grid" v-if="!transaction_id">
              <div class="full-width">

                <div class="text-purple-500 mt-2">
                  <div class="font-bold text-yellow-500 mb-2">
                    {{ $t("Payment Instructions") }}
                  </div>
                  <ul>
                    <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                    <li>
                      {{
                        $t(
                          "select_any_of_the_payment_methods_available_and_provide_required_information"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t("This online purchase will incur transaction charges")
                      }}
                    </li>
                    <li>
                      <span class="text-pink-500 font-bold">Please make sure you have enough balance
                        on the Mobile Wallet. </span>
                    </li>
                    <li v-if="msisdn">
                      {{ $t("Your Wallet number") }} is {{ msisdn }}
                    </li>
                  </ul>

                  <Message class="mb-2"
                    icon="pi pi-sen" severity="warn" :closable="false">HINT: Mature Application Forms are ONLY for applicants 25 years and above.</Message>

                  <section class="page-section mb-3 ">
                    <div class="container">
                      <div class="card bg-blue-100">
                        <div class=" text-primary font-semibold">
                         Note: Voucher purchased will only be usable for applications within the chosen
                          academic year.
                        </div>
                      </div>
                    </div>
                  </section>

                  
                </div>
              </div>
              <div class="full-width">
                <form ref="observer" tag="form" @reset="resetForm" @submit.prevent="startPayment()">
                  <div class="card nice-shadow-5">
                    <div class="grid">
                      <div class="col-12 sm:col-12 md:col-6">
                        <div class="mb-2 font-bold text-sm">
                          {{ $t("Application Form Type") }} *
                        </div>
                        <div class="">
                          <api-data-source @loaded="(response) =>
                            mapOptionField(response, 'transaction_type')"
                            api-path="components_data/application_fees">
                            <template v-slot="req">
                              <Dropdown class="w-full" :class="getErrorClass('transaction_type')" :loading="req.loading"
                                optionLabel="label" optionValue="value" ref="ctrltransaction_type"
                                v-model="formData.transaction_type" :options="req.response" :label="$t('select')"
                                :placeholder="$t('select')">
                                <template #option="slotProps">
                                  <div class="flex align-items-center">
                                    <div>
                                      <div>{{ slotProps.option.label }}</div>
                                      <div class="text-sm text-grey-500">{{
                                        slotProps.option.formtype }} [{{
    slotProps.option.academic_year }}]</div>
                                      <!-- <div class="text-sm text-purple-700">Cost: GHS
                                                                                {{ slotProps.option.amount }} + Charges GHS
                                                                                {{ slotProps.option.charges }} </div> -->
                                    </div>
                                  </div>
                                </template>
                              </Dropdown>
                            </template>
                          </api-data-source>
                        </div>
                      </div>

                      <div class="col-12 sm:col-12 md:col-6">
                        <div class="mb-2 font-bold text-sm">
                          {{ $t("payment_method") }} *
                        </div>
                        <div class="">
                          <!-- <div class="field mb-0"> -->
                          <Dropdown class="w-full" :class="getErrorClass('payment_method')" optionLabel="label"
                            optionValue="value" ref="ctrlpayment_method" v-model="formData.payment_method"
                            :options="$menus.network_list" :label="$t('payment_method')" :placeholder="$t('select')"
                            @change="validatePaymentMethod">
                            <template #option="slotProps">
                              <div class="flex align-items-center">
                                <div class="ml-2" v-if="slotProps.option.image">
                                  <img style="width: 42px;margin-right: 10px;" :src="slotProps.option.image" alt="" />
                                </div>
                                <div>
                                  <div>{{ slotProps.option.label }}</div>
                                  <div v-if="slotProps.option.caption" class="text-sm text-500">
                                    {{ slotProps.option.caption }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </Dropdown>
                          <!-- </div> -->
                        </div>
                      </div>

                      <div class="col-12 md:col-12">
                        <div class="mb-2 font-bold text-sm">
                          {{ $t("email_address") }} *
                        </div>
                        <div class="">
                          <InputText ref="ctrlpayer_email" v-model.trim="formData.payer_email"
                            :label="$t('email_address')" type="email" :placeholder="$t('email_address')" class="w-full"
                            maxlength="40" :class="getErrorClass('payer_email')">
                          </InputText>
                        </div>
                      </div>

                      <div v-if="formData.payment_method == card" class="card nice-shadow-5 mx-2 mt-2">
                        <div class="grid">
                          <div class="col-12 md:col-7">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("card_holder_name") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText ref="ctrlcard_holder" v-model.trim="formData.card_holder"
                                :label="$t('card_holder_name')" type="text" class="w-full"
                                style="text-transform: uppercase" :class="getErrorClass('card_holder')">
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div class="col-12 md:col-5">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("card_number") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputMask ref="ctrlcard_number" v-model.trim="formData.card_number"
                                :label="$t('card_number')" type="text" placeholder="9999 9999 9999 9999" class="w-full"
                                mask="9999 9999 9999 9999" :class="getErrorClass('card_number')">
                              </InputMask>
                              <!-- </div> -->
                            </div>
                          </div>

                          <div class="col-12 md:col-12">
                            <div class="grid">
                              <div class="col-12 md:col-4">
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("expiry_month") }} *
                                </div>
                                <div class="">
                                  <!-- <div class="field mb-0"> -->
                                  <Dropdown class="w-full" :class="getErrorClass('expiry_month')" optionLabel="label"
                                    optionValue="value" ref="ctrlexpiry_month" v-model="formData.expiry_month"
                                    :options="$menus.expiry_monthItems" :label="$t('expiry_month')"
                                    :placeholder="$t('select')"></Dropdown>
                                  <!-- </div> -->
                                </div>
                              </div>
                              <div class="col-12 md:col-4">
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("expiry_year") }} *
                                </div>
                                <div class="">
                                  <!-- <div class="field mb-0"> -->
                                  <Dropdown class="w-full" :class="getErrorClass('expiry_year')" ref="ctrlexpiry_year"
                                    optionLabel="label" optionValue="value" v-model="formData.expiry_year"
                                    :options="$menus.expiry_yearItems" :label="$t('expiry_year')"
                                    :placeholder="$t('select')"></Dropdown>
                                  <!-- </div> -->
                                </div>
                              </div>
                              <div class="col-12 md:col-4">
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("cvv") }} *
                                </div>
                                <div class="">
                                  <!-- <div class="field mb-0"> -->
                                  <InputMask ref="ctrlcvv" v-model.trim="formData.cvv" :label="$t('cvv')" type="text"
                                    placeholder="999" pattern="^[0-9]+$" class="w-full" mask="999"
                                    :class="getErrorClass('cvv')">
                                  </InputMask>
                                  <!-- </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--[form-content-end]-->
                  <div class="text-center my-3">
                    <Button type="submit" :label="$t('continue')" icon="pi pi-send" :loading="saving" />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
            <!-- v-else-if="transaction_id &&transaction_message == 'WALLET' &&transaction_code == '0'" -->
            <div class="col-12" v-else-if="transaction_id && transaction_message == 'WALLET' && transaction_code == '0'">
              <div class="card nice-shadow-5">
                <div class="mb-3">
                  <i style="font-size: 3em" class="pi pi-wallet"></i>
                </div>
                <div class="">
                  <div class="font-bold mb-2">
                    {{ $t("payment_authorization_request_sent") }}
                  </div>
                  <div class="text-purple-500">
                    <ul>
                      <li>
                        {{ $t("to_complete_payment_you_will_receive_a_prompt_on_your_mobile_device")
                        }}
                      </li>
                      <li>
                        {{ $t("after_entering_your_pin_kindly_wait_for_a_confirmation_text") }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mt-3 text-center">
                <Button :label="$t('i_have_paid')" class="p-button-primary px-5  pulse-button" :loading="saving"
                  @click="confirmPayment()" />
              </div>
            </div>
            <div class="col-12" v-else-if="transaction_code == '2' || transaction_code == '3'">
              <div class="card nice-shadow-5">
                <div class="mb-3">
                  <i style="font-size: 3em" class="pi pi-credit-card"></i>
                </div>
                <div class="">
                  <div class="font-bold mb-2">
                    {{ $t("please_verify_first_time_use_of_card_with_amount_deducted_from_bank_account")
                    }}
                  </div>
                  <div class="text-purple-500 mb-2">
                    {{ $t("an_amount_of_money_has_been_deducted_from_your_bank_account") }}
                  </div>
                  <div class="text-pink-400 text-sm">
                    {{ $t("card_will_be_blocked_after_3_failed_verification_attempts") }}
                  </div>

                  <div class="card nice-shadow-5 mt-3">
                    <div class="grid">
                      <div class="col-12 md:col-12">
                        <div class="mb-2 font-bold text-sm">
                          {{ $t("amount") }} *
                        </div>
                        <div class="">
                          <InputText ref="ctrlcard_otp" v-model="card_otp" :label="$t('amount')" type="number"
                            :placeholder="$t('amount')" pattern="^[0-9.]+$" class="w-full" maxlength="5">
                          </InputText>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3 text-center">
                    <Button :label="$t('verify')" class="p-button-primary px-5" :loading="saving"
                      @click="verifyCardOtp" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="grid">
            <div class="col-12 md:col-12">
              <div class="card nice-shadow-5">
                <div class="mb-3">
                  <i style="font-size: 3em" class="pi pi-check text-green-500"></i>
                </div>
                <div class="">
                  <div class="font-bold mb-2">
                    {{ $t("Payment Successful") }}
                  </div>
                  <Divider></Divider>
                  <div class="grid q-col-gutter-x-md">
                    <div class="col-12">
                      <div class="grid">
                        <div class="md:col-6">
                          <span class="mr-1">{{ $t("amount") }} (GHS): </span>
                        </div>
                        <div class="md:col-6">
                          {{ `205` }}
                        </div>
                      </div>
                      <hr />
                      <div class="grid">
                        <div class="md:col-6">
                          <span class="mr-1">{{ $t("transaction_id") }}:</span>
                        </div>
                        <div class="md:col-6">
                          {{ transaction_id }}
                        </div>
                      </div>
                      <hr />
                      <div class="grid">
                        <div class="md:col-6">
                          <span class="mr-1"> {{ $t("telephone") }}: </span>
                        </div>
                        <div class="md:col-6">
                          {{ msisdn }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4 text-center">
                <div class="text-muted text-purple-600 mx-5 mb-3 px-2">
                  Please click the "View Receipt" button below to see your <b>Serial Number</b> and
                  <b>Pin</b>. Do NOT
                  leave this page without clicking this button.
                </div>
                <a class="p-button-primary px-5" :href="`${return_url}`" target="_blank">
                  <Button icon="pi pi-print" :label="$t('view_receipt')" class="p-button-primary py-3 pulse-button">
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { email, required, requiredIf } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { EditPageMixin } from "../../mixins/editpage.js";
import { PageMixin } from "../../mixins/page.js";
import { ApiService } from "../../services/api";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "paymentApplicantsPage",
  components: {},
  mixins: [PageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
  },
  data() {
    return {
      formData: {
        transaction_type: "",
        telephone_number: "",
        payer_email: "",
        payment_method: "",
        card_details: "",
        cvv: "",
        card_holder: "",
        card_number: "",
        expiry_month: "",
        expiry_year: "",
      },
      return_url: "",
      msisdn: "",
      payment_status: "Pending",
      card_otp: "",
      transaction_id: null,
      transaction_code: "",
      transaction_message: "",
      transaction_msisdn: "",
      submitted: false,
      card: "CARD",
      saving: false,
      deadLine: null,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return this.$t("Purchase Application Form");
      },
    },
  },
  validations() {
    let formData = {
      transaction_type: { required },
      payment_method: { required },
      payer_email: { required, email },
      cvv: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      card_holder: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      card_number: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      expiry_month: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
      expiry_year: {
        required: requiredIf(() => {
          const esit = this.formData.payment_method == this.card;
          return esit;
        }),
      },
    };
    return { formData };
  },
  methods: {
    async startPayment() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      this.saving = true;
      this.formData.telephone_number = this.$route.query.token || null;
      let form = this.normalizedFormData();

      let card_details = form.card_number
        ? form.card_number.replace(/\s/g, "") +
        "||" +
        this.$utils.upper(form.card_holder) +
        "||" +
        form.expiry_month +
        "||" +
        form.expiry_year +
        "||" +
        form.cvv
        : " ";

      let payload = {
        transaction_type: form.transaction_type,
        telephone_number: form.telephone_number,
        payment_method: form.payment_method,
        payer_email: form.payer_email,
        card_details: Buffer.from(card_details).toString("base64"),
      };

      this.$api.post("applicants/payment", payload).then(
        (response) => {
          this.saving = false;
          let error_codes = ["1", "4", "5"];
          if (
            response.data.transaction_message == "CARD" &&
            response.data.transaction_code == "0"
          ) {
            this.$emit("next", this.pageIndex);
          } else if (error_codes.includes(response.data.transaction_code)) {
            this.transaction_id = null;
            this.flashMsg(this.$t("payment_failed_auto_refund_initiated_please_check_funds"), null, "error");
          } else {
            this.transaction_id = response.data.transaction_id;
            this.transaction_code = response.data.transaction_code;
            this.transaction_message = response.data.transaction_message;
            this.transaction_msisdn = response.data.transaction_msisdn;
            this.return_url = response.data.return_url;
          }
          this.closeDialogs();
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    async confirmPayment() {
      this.saving = true;
      let payload = { transaction_id: this.transaction_id };
      this.$api.post("payments/confirm", payload).then(
        (response) => {
          this.saving = false;
          let record = response.data;
          if (record.response_code && record.response_code == "0") {
            this.payment_status = "Paid";
          } else {
            this.flashMsg(this.$t("awaiting_payment_authorization_and_confirmation"), null, "warn");
          }
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    async verifyCardOtp() {
      this.saving = true;
      let payload = {
        card_otp: this.card_otp,
        transaction_id: this.transaction_id,
        msisdn: this.transaction_msisdn,
        transaction_type: this.formData.transaction_type,
      };
      this.$api.post("payments/verifyotp", payload).then(
        (response) => {
          this.saving = false;
          let record = response.data;
          this.payment_status = "Paid";
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    validatePaymentMethod() {
      if (this.formData.payment_method != this.card) {
        this.formData.card_holder = "";
        this.formData.card_details = "";
        this.formData.card_number = "";
        this.formData.expiry_month = "";
        this.formData.expiry_year = "";
        this.formData.cvv = "";
      }
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        telephone_number: "",
        transaction_type: "",
        payment_method: "",
        card_details: "",
        cvv: "",
        card_number: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },


  },
  watch: {},
  async mounted() {
    this.msisdn = this.$route.query.msisdn || null;
  },
  async created() { },
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>